/* Footer Image Styling */
.footer img {
  border: 3px solid gray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  width: 150px; /* Adjust width */
  height: 150px; /* Adjust height */
}

/* Footer Text Styling */
.footer_text {
  margin-top: 10px;
  font-size: 18px;
  color: gray;
  text-align: center;
  max-width: 90%; /* Limit text width for better readability */
  margin-left: auto;
  margin-right: auto;
}

.navlink-text {
  text-decoration: none;
  color: inherit;
  display: inline-block;
  margin-top: 10px; /* Adds space between text and link */
  font-size: 16px; /* Consistent size for readability */
}

/* Responsive Styling */
@media (max-width: 768px) {
  /* Adjust footer image size on smaller screens */
  .footer img {
    width: 120px;
    height: 120px;
  }

  .footer_text {
    font-size: 16px; /* Smaller font size for readability */
  }

  .navlink-text {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  /* Further size adjustments for extra small screens */
  .footer img {
    width: 100px;
    height: 100px;
  }

  .footer_text {
    font-size: 14px;
  }

  .navlink-text {
    font-size: 12px;
    margin-top: 8px;
  }
}
.google-btn {
  text-decoration: none; /* Removes underline */
  color: gray;
  transition: all 0.3s ease; /* Smooth hover effect */
}
