/* Banner Image Styling */

@media (max-width: 576px) {
  .image_fixed {
    height: 200px; /* Set a smaller fixed height for mobile */
  }
}

/* Contact Section Styles */
.contact-section {
  background-color: #f8f9fa;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px; /* Padding for better spacing on mobile */
}

.contact-content {
  color: #000;
}

.contact-name {
  font-size: 36px;
  font-weight: bold;
  animation: slide-in 1s ease-in-out;
  color: #ad3e00;
  margin-bottom: 10px; /* Added space below name */
}

.contact-address {
  font-size: 20px;
  color: #555;
  animation: fade-in 1.2s ease-in-out;
  margin: 5px 0; /* Margin for spacing */
}

/* Animations */

/* Responsive Styling */
@media (max-width: 768px) {
  .contact-section {
    height: auto; /* Adjust height for smaller screens */
    padding: 20px; /* Ensures space on mobile */
  }

  .contact-name {
    font-size: 28px;
  }

  .contact-address {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  /* Further adjustments for extra small screens */

  .contact-name {
    font-size: 24px;
  }

  .contact-address {
    font-size: 16px;
  }

  /* Smaller margin between contact info */
  .contact-section {
    padding: 15px;
  }
}

/* card */
/* Card container */
.food-card {
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(135deg, #ffffff, #f7f9fc);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.food-card:hover {
  transform: translateY(-10px); /* Lift card on hover */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Image styling */
.food-card img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: transform 0.3s ease;
}

.food-card:hover img {
  transform: scale(1.1); /* Slight zoom-in on hover */
}

/* Card title styling */
.food-card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.food-card:hover .card-title {
  color: #007bff; /* Change color on hover */
}

/* Card text styling */
.food-card .card-text {
  font-size: 0.95rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .food-card {
    margin-bottom: 20px;
  }
}
