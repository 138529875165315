/* Styling for the header */
.header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;
}

/* Flexbox layout for left, center (logo), and right */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styling for left and right text */
.header-left,
.header-right {
  flex: 1;
  text-align: center;
}

.header-text {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ad3e00;
  animation: fadeIn 2s ease-in-out;
}

/* Styling for the centered logo */
.header .logo {
  text-align: center;
}

.header .header-logo {
  max-width: 150px;
  height: auto;
}

/* Logo bounce animation */
.animate-logo {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Text fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  .header .header-logo {
    max-width: 120px;
  }

  .header-text {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header .header-logo {
    max-width: 100px;
    animation: bounce 3s infinite;
  }

  .header-text {
    font-size: 0.9rem;
  }
}
