.container_addfood {
  width: 100%; /* Desktop and larger screens */
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .container_addfood {
    width: 100%; /* Full width on mobile */
    padding: 0 1rem; /* Optional padding for better spacing on small screens */
  }
}
/* Background styling */
.first-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  padding: 20px;
}

/* Button container for responsiveness */
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Button styling */
.btn-action {
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
}

/* Delete button */
.btn-delete {
  background-color: #ff6b6b;
  color: white;
}

.btn-delete:hover {
  background-color: #ff5252;
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(255, 107, 107, 0.3);
}

/* Add button */
.btn-add {
  background-color: #4caf50;
  color: white;
}

.btn-add:hover {
  background-color: #45a049;
  transform: scale(1.1);
  box-shadow: 0 8px 15px rgba(76, 175, 80, 0.3);
}

/* Back link styling */
.back-link {
  margin-top: 20px;
  text-decoration: none;
  color: #333;
  font-size: 1.1rem;
  font-weight: bold;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.back-link:hover {
  color: #555;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Responsive styling */
@media (max-width: 768px) {
  .btn-action {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .button-container {
    gap: 10px;
  }
}
